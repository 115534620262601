<template>
  <div>
    <div class="d-flex flex-row mb-6 mt-6">
      <div class="text-2xl mb-6">
        {{ partnerAccountsTotalCount }} comptes pour
        {{ partnersTotalCount }} partenaires
        <span v-if="loading" class="text-center">
          <v-progress-circular
            color="primary"
            indeterminate
          ></v-progress-circular>
        </span>
        <!-- span v-else>
          <v-btn
            to="/admin/partner/account/edit"
            class="mx-5"
            color="primary"
            icon
            fab
            outlined
            elevation="4"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
          </v-btn>
        </span -->
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small v-bind="attrs" v-on="on" class="me-n3 mt-n2">
              <v-icon size="22">
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link :to="{ name: 'gmail-token-helper' }">
              <v-list-item-title>Gmail token helper</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <v-text-field
      v-model="searchStr"
      label="Search"
      @input="search"
      dense
    ></v-text-field>

    <div v-if="data.length !== 0">
      <v-data-table
        dense
        disable-sort
        :headers="headers"
        hide-default-footer
        hide-default-header
        :items="filteredData"
        item-key="id"
        group-by="partner_id"
        :itemsPerPage="200"
      >
        <template v-slot:group.header="{ items, isOpen, toggle }">
          <th colspan="1">
            <v-icon @click="toggle" class="mr-2">
              {{ isOpen ? icons.mdiMinus : icons.mdiPlus }}
            </v-icon>
            Partenaire {{ items[0].partner_name }} (id:{{
              items[0].partner_id
            }})
          </th>
          <th colspan="1" class="text-left">
            <!--:label="`Active: ${items[0].partner_is_active.toString()}`"-->
            <v-switch
              v-model="items[0].partner_active"
              :data-partner-id="items[0].partner_id"
              @click="
                togglePartner(items[0].partner_id, items[0].partner_active)
              "
              class="partner-switch"
              outlined
              dense
              inset
            >
              <template v-slot:label>
                <span :class="items[0].is_loading ? 'visible' : 'invisible'">
                  <v-progress-circular
                    :indeterminate="items[0].is_loading"
                  ></v-progress-circular>
                </span>
              </template>
            </v-switch>
          </th>
          <th colspan="5" class="text-right"></th>
        </template>
        <template v-slot:item="{ item, index }">
          <tr>
            <td></td>
            <td></td>
            <td>
              Compte
              <!--a :href="`/admin/partner/account/view/${item.id}`">
                <strong>{{ item.name }}</strong>
              </a-->
              <a @click="openDialogPartnerView(item.id)">
                <strong>{{ item.name }}</strong>
              </a>
              (id:{{ item.id }})
            </td>
            <td>{{ item.code }}</td>
            <td>{{ item.active ? "actif" : "inactif" }}</td>
            <!-- <td><v-switch v-model="item.active" outlined dense></v-switch></td> -->
            <!-- v-on:click="editItem" -->
            <td>
              <v-btn
                :data-id="item.id"
                :to="`/admin/partner/account/edit/${item.id}`"
                color="primary"
                icon
                fab
                small
                elevation="4"
                class="mx-2"
              >
                <v-icon>
                  {{ icons.mdiPencil }}
                </v-icon>
              </v-btn>
              <v-btn
                :data-id="item.id"
                v-on:click="duplicateItem"
                color="primary"
                icon
                fab
                small
                elevation="4"
                class="mx-2"
              >
                <v-icon>
                  {{ icons.mdiContentDuplicate }}
                </v-icon>
              </v-btn>
              <v-btn
                :data-id="item.id"
                v-on:click="removeItem"
                color="error"
                icon
                fab
                small
                elevation="4"
                class="mx-2"
              >
                <v-icon>
                  {{ icons.mdiDelete }}
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <div v-else>
      {{ noDataMessage }}
    </div>
    <v-snackbar
      v-model="isSnackbarVisible"
      :timeout="5000"
      top
      :color="snackbarColor"
    >
      {{ snackbarText }}
    </v-snackbar>
    <v-dialog v-model="dialogPartnerViewVisible" max-width="1000">
      <partner-account-modal-view
        :partnerAccountId="partnerAccountId"
      ></partner-account-modal-view>
    </v-dialog>
    <v-dialog v-model="dialogConfirmVisible" max-width="800">
      <v-card>
        <v-card-title class="text-h5">
          {{ dialogConfirmTitle }}
        </v-card-title>
        <v-card-text>
          {{ dialogConfirmText }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" @click="dialogConfirmCallback">
            Oui
          </v-btn>
          <v-btn color="green darken-1" @click="dialogConfirmVisible = false">
            Abandonner
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// cf https://codepen.io/cosmoworx/pen/MWwWoXq
import { ref } from "@vue/composition-api";
import {
  mdiMinus,
  mdiPlus,
  mdiPencil,
  mdiDelete,
  mdiDotsVertical,
  mdiContentDuplicate,
} from "@mdi/js";
import axios from "@axios";
import PartnerAccountModalView from "@/components/admin/partner/account/modals/View.vue";

export default {
  name: "PartnerAccountList",
  components: {
    PartnerAccountModalView,
  },
  props: {},
  watch: {},
  setup() {
    const isSnackbarVisible = ref(false);
    return {
      isSnackbarVisible,
    };
  },
  created() {
    this.dataMessage = this.defaultNoDataMessage;
  },
  data() {
    return {
      loading: false,
      dataMessage: "",
      headers: [
        { text: "Partner name", value: "partner_name" },
        { text: "Name", value: "name" },
        { text: "Action", value: "aaa" },
        { text: "Action", value: "bbb" },
      ],
      data: [],
      filteredData: [],
      defaultNoDataMessage: "Retrieving data ...",
      noDataMessage: "Data not found.",
      searchStr: "",
      searchTimeout: null,
      snackbarColor: "info", // info, primary, success, warning, error
      snackbarText: "",
      dialogPartnerViewVisible: false,
      partnerAccountId: null,
      dialogConfirmVisible: false,
      dialogConfirmTitle: "",
      dialogConfirmText: "",
      dialogConfirmCallbackFunction: null,
      icons: {
        mdiPencil,
        mdiMinus,
        mdiPlus,
        mdiDelete,
        mdiDotsVertical,
        mdiContentDuplicate,
      },
      partnerAccountsTotalCount: 0,
      partnersTotalCount: 0,
    };
  },
  methods: {
    openDialogPartnerView(partnerAccountId) {
      this.partnerAccountId = partnerAccountId;
      this.dialogPartnerViewVisible = true;
    },
    togglePartnerLoading(id, value) {
      for (const i in this.filteredData) {
        if (!value || this.filteredData[i]["partner_id"] == id)
          this.filteredData[i]["is_loading"] = value;
      }
    },
    async togglePartner(id, newValue) {
      this.togglePartnerLoading(id, true);
      const response = await axios.put(
        `/partners/toggle-active/${id}/${newValue ? "true" : "false"}`
      );
      if (response.status === 200) {
        this.showSnackBar(
          `Partner successfully ${newValue ? "activated" : "deactivated"}`,
          "success"
        );
      } else {
        this.showSnackBar("Something went wrong.", "error");
      }
      setTimeout(this.getData, 500);
    },
    editItem(e) {
      const selectedId = e.currentTarget.getAttribute("data-id");
      this.$router.push({
        to: `/admin/partner/account/create-or-update/${selectedId}`,
      });
    },
    getItemById(id) {
      return this.filteredData.filter(function (el) {
        return el.id == id;
      })[0];
    },
    async duplicateItem(e) {
      const selectedId = e.currentTarget.getAttribute("data-id");
      const selectedItem = this.getItemById(selectedId);
      this.showDialogConfirm(
        `Dupliquer : ${selectedItem.partner_name} > ${selectedItem.name} (id:${selectedId}) ?`,
        "Il faudra ensuite l'éditer pour modifier son nom, son code, et ses paramètres.",
        async () => {
          const url = `/partner_accounts/create_duplicate/${selectedId}`;
          const response = await axios.get(url);
          if (this.raiseForResponseError(response)) {
            return;
          }
          this.showSnackBar(
            "Partner account successfully duplicated",
            "success"
          );
          setTimeout(this.getData, 500);
        }
      );
    },
    async removeItem(e) {
      const selectedId = e.currentTarget.getAttribute("data-id");
      const selectedItem = this.getItemById(selectedId);
      this.showDialogConfirm(
        `Supprimer : ${selectedItem.partner_name} > ${selectedItem.name} (id:${selectedId}) ?`,
        "Après cette suppression, il ne sera plus possible de le récupérer.",
        async () => {
          const response = await axios.delete(
            `/partner_accounts/${selectedId}`,
            {
              params: {},
            }
          );
          if (this.raiseForResponseError(response, 204)) {
            return;
          }
          this.showSnackBar("Partner account successfully removed", "success");
          setTimeout(this.getData, 500);
        }
      );
    },
    async getData() {
      this.data = [];
      this.loadingPartner = {};
      this.dataMessage = this.defaultDataMessage;
      const url = `/partner_accounts/`;
      this.loading = true;
      const { data } = await axios.get(url);
      this.loading = false;
      if ("error" in data) {
        this.dataMessage = data.error;
        return;
      }
      this.data = data.items;
      for (const i in this.data) {
        this.data[i]["is_loading"] = false;
      }
      this.filteredData = this.data;
      this.partnerAccountsTotalCount = data.total;
      let partnersIds = this.data.map((x) => {
        return x.partner_id;
      });
      partnersIds = [...new Set(partnersIds)];
      this.partnersTotalCount = partnersIds.length;
    },
    search() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }
      setTimeout(() => {
        if (this.searchStr == "") {
          this.filteredData = this.data;
          return;
        }
        const filteredData = [];
        const searchStrLC = this.searchStr.toLowerCase();
        for (let i = 0; i < this.data.length; i++) {
          if (
            this.data[i]["name"].toLowerCase().includes(searchStrLC) ||
            this.data[i]["partner_name"].toLowerCase().includes(searchStrLC)
          ) {
            filteredData.push(this.data[i]);
          }
        }
        this.filteredData = filteredData;
      }, 500);
    },
    showSnackBar(text, color = "info") {
      this.snackbarColor = color; // info, primary, success, warning, error
      this.snackbarText = text;
      this.isSnackbarVisible = true;
    },
    dialogConfirmCallback() {
      this.dialogConfirmVisible = false;
      if (this.dialogConfirmCallbackFunction) {
        this.dialogConfirmCallbackFunction();
      }
      this.dialogConfirmCallbackFunction = null;
    },
    showDialogConfirm(title, text, callbackFunction) {
      this.dialogConfirmTitle = title;
      this.dialogConfirmText = text;
      this.dialogConfirmCallbackFunction = callbackFunction;
      this.dialogConfirmVisible = true;
    },
    dateToStr(date) {
      return date.toISOString().split("T")[0];
    },
    raiseForResponseError(response, status = 200) {
      if (response.status != status) {
        this.showSnackBar("Something went wrong.", "error");
        return true;
      }
      if (
        "data" in response &&
        response.data &&
        typeof response.data === "object" &&
        "error" in response.data
      ) {
        this.showSnackBar(`Error. ${response.data.error}`, "error");
        return true;
      }
      return false;
    },
  },
  computed: {},
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.v-row-group__header {
  height: 25px;
}
.visible {
  visibility: visible;
}
.invisible {
  visibility: hidden;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
  font-size: inherit !important;
  height: auto !important;
}
/* .v-data-table .v-row-group__header .v-input--selection-controls { */
.partner-switch {
  margin: 0px;
  padding: 0px;
}
.partner-switch >>> .v-input__control {
  max-height: 30px;
}
.partner-switch >>> .v-messages {
  display: none;
}
</style>
